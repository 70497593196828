import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SectionCard from "../../demand_capacity/section_card/SectionCard";

import WeekViewBarChart from "../week_view_bar_chart/WeekViewBarChart";
import DayViewWrapper from "../day_view_wrapper/DayViewWrapper";

export const WeekViewSection = ({ loadingChartData, chartData, labels, dailyData, handleDailyDataSubmit, dateFrom, dailyDataLoading }) => {
  const [dailyBreakdownTabValue, setDailyBreakdownTabValue] = useState(0);

  const loadDailyBreakdown = (dateFrom) => {
    handleDailyDataSubmit(dateFrom);
    setDailyBreakdownTabValue(0);
  }

  return (
    <>
      <SectionCard title="Week View" loadingData={loadingChartData}>
        {chartData && <WeekViewBarChart chartData={chartData} labels={labels} />}
        <LoadingButton onClick={()=>loadDailyBreakdown(dateFrom)} loading={dailyDataLoading} variant="contained" className="weekly_planner_daily_data_btn">
          {dailyDataLoading ? 'Loading...' : 'Load Daily Breakdown'}
        </LoadingButton>
      </SectionCard>
      <DayViewWrapper dailyData={dailyData} labels={labels} dateFrom={dateFrom}
        handleDailyDataSubmit={handleDailyDataSubmit} dailyBreakdownTabValue={dailyBreakdownTabValue} setDailyBreakdownTabValue={setDailyBreakdownTabValue} />
    </>
  );
};

export default WeekViewSection;
